html {
  --bg: #1a1a23;
}

body {
  background: var(--bg);
  color: #ffffff;
  font-family: Fira Code,monospace;
  padding: 0 30px;

  a {
    color: #ffffff;
  }

  @media all and (max-width: 1024px) {
    padding: 0 15px;
  }
}

.app {
}

.tree {
  white-space: pre-wrap;

   .Typewriter__wrapper {
   }
}

input {
  border: none;
  background: none;
  font-family: inherit;
  color: #ffffff;
  font-size: 16px;
  outline: none;
  padding: 0;
}

.command {
  display: flex;

  &__prefix {
    margin-right: 10px;
    flex-shrink: 0;
  }

  &__content {
    flex: 1;
  }

  &-error {
    color: #e30664;
  }
}